/** @jsxImportSource @emotion/react */
import { ClassNames, css } from '@emotion/react';
import { Router } from '@reach/router';
import React from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { ToastContainer, cssTransition } from 'react-toastify';
import { Index } from './screens/index';
import { Asset } from './screens/asset';
import { PriceList } from './screens/price-list';
import { RequestMaterialNumber } from './screens/requestmaterialnumber';

import 'animate.css/animate.min.css';

const fadeTransition = cssTransition({
  enter: 'animate__animated animate__fadeInDown',
  exit: 'animate__animated animate__fadeOutUp',
});

const CloseButton = ({ closeToast }) => (
  <i
    css={css`
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
    `}
    onClick={closeToast}
  >
    <IoIosCloseCircleOutline />
  </i>
);

function AuthenticatedApp() {
  return (
    <React.Fragment>
      <ClassNames>
        {({ css }) => (
          <ToastContainer
            autoClose={5000}
            className={css({
              position: 'fixed',
              zIndex: 9999,
              WebkitTransform: 'translate3d(0, 0, 9999px)',
              width: '320px',
              top: '1em',
              left: '50%',
              marginLeft: '-160px',
            })}
            closeOnClick={true}
            closeButton={<CloseButton />}
            draggable={false}
            hideProgressBar={true}
            pauseOnFocusLoss={false}
            position="top-center"
            toastClassName={css({
              alignItems: 'center',
              backgroundColor: 'rgb(41, 81, 115)',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              padding: '30px',
              position: 'relative',
              boxShadow: '0 0 8px #888888',
            })}
            transition={fadeTransition}
          />
        )}
      </ClassNames>
      <Router>
        <Index path="/" />
        <Asset path="/asset/:mediaId/*" />
        <PriceList path="/price-list" />
	<RequestMaterialNumber path="/workflow-request-matnum" />
      </Router>
    </React.Fragment>
  );
}

export default AuthenticatedApp;
