/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useCallback, useState, useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { useInfiniteQuery } from 'react-query';
import { useQueryParam } from 'use-query-params';
import { useRole } from '../../context/role-context';
import { fetchMedia } from '../../lib/client-api';
import Layout from '../../components/layout';
import SearchResults from '../../components/search/results';
import SearchForm from '../../components/search/form';
import LoadingIndicator from '../../components/loading-indicator';
import { useIntersectionObserver } from '../../hooks/use-intersection-observer';
import Placeholder from '../../components/placeholder';

export function AssetPicker({ onAssetClick }) {
  const { role } = useRole();
  const [productId] = useQueryParam('productid');
  const [groupId] = useQueryParam('groupid');

  const pimReference = productId || groupId;

  const [search, setSearch] = useState({
    property_PIMIsDerivative: 'no',
  });
  const [loadMoreRef, setLoadMoreRef] = useState({ current: null });
  const onRefSet = useCallback((ref) => {
    if (ref !== null) {
      setLoadMoreRef({ current: ref });
    }
  }, []);

  const {
    data,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
    hasNextPage,
    status,
  } = useInfiniteQuery(
    ['fetchMedia', pimReference, search],
    ({ pageParam = 1 }) =>
      fetchMedia({ page: pageParam, productId: pimReference, search }),
    {
      getPreviousPageParam: (firstPage, allPages) => {
        const currentPagesCount = allPages.length;

        if (currentPagesCount * 16 > 0) {
          return currentPagesCount - 1;
        }

        if (currentPagesCount) return false;
      },
      getNextPageParam: (lastPage, allPages) => {
        const currentPagesCount = allPages.length;

        if (currentPagesCount * 16 < lastPage.count.total) {
          return currentPagesCount + 1;
        }

        return false;
      },
    }
  );

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  useEffect(() => {
    let order = {};

    if (pimReference) {
      order = { order: { property_PIMRoles: `${pimReference}_${role.value}` } };
    }

    setSearch({
      property_PIMIsDerivative: 'no',
      ...order,
    });
  }, [pimReference, role]);

  function handleSearch(data) {
    let order = {};

    if (pimReference && role) {
      order = { order: { property_PIMRoles: `${pimReference}_${role.value}` } };
    }

    setSearch({
      property_PIMIsDerivative: 'no',
      ...data,
      ...order,
    });
  }

  return (
    <Layout
      title="Choose asset"
      toolbar={<SearchForm hideRoleSelect={true} onSearch={handleSearch} />}
    >
      {status === 'loading' ? (
        <ul
          css={css`
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0 -15px;
            padding: 15px 0;
            position: relative;

            & + & {
              margin-top: -30px;
            }

            .copy {
              position: absolute;
              z-index: 10;
              right: 10px;
              top: 10px;
            }
          `}
        >
          {Array(16)
            .fill(1)
            .map((_, i) => (
              <li
                css={css`
                  margin: 15px;
                  width: calc(25% - 30px);
                `}
                key={i}
              >
                <Placeholder height={175} width={234} />
              </li>
            ))}
        </ul>
      ) : status === 'error' ? (
        <div
          css={css`
            padding: 30px;
          `}
        >
          There was an error.
        </div>
      ) : (
        <React.Fragment>
          {data.pages.map((page, i) => {
            return (
              <React.Fragment key={i}>
                <SearchResults
                  data={page.media}
                  isInitial={isLoading}
                  onClick={onAssetClick}
                  skipToCrop={true}
                />
              </React.Fragment>
            );
          })}
          {hasNextPage && (
            <div
              ref={onRefSet}
              style={{
                display: 'flex',
                height: '39px',
                justifyContent: 'center',
                overflow: 'hidden',
                paddingBottom: '15px',
              }}
            >
              {isFetchingNextPage ? (
                <LoadingIndicator
                  color={[41, 85, 115]}
                  height="24px"
                  width="24px"
                />
              ) : null}
            </div>
          )}
        </React.Fragment>
      )}
    </Layout>
  );
}
