import React, { useState } from 'react';

const RoleContext = React.createContext();

function RoleProvider(props) {
  const [role, setRole] = useState();

  return <RoleContext.Provider value={{ role, setRole }} {...props} />;
}

function useRole() {
  const context = React.useContext(RoleContext);

  if (context === undefined) {
    throw new Error('useRole must be used within a RoleProvider');
  }

  return context;
}

export { RoleProvider, useRole };
