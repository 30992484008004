import React from 'react';
import { useQuery } from 'react-query';
import { bootstrapAppData } from '../lib/bootstrap';
import * as authClient from '../lib/auth-client';

const AuthContext = React.createContext();

function AuthProvider(props) {
  const { data = { user: null }, isLoading, refetch } = useQuery(
    'bootstrapAppData',
    bootstrapAppData
  );

  const login = async (token) => {
    await authClient.login(token);

    refetch();
  };

  const logout = async () => {
    await authClient.logout();

    refetch();
  };

  if (isLoading) {
    return null;
  }

  return <AuthContext.Provider value={{ data, login, logout }} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
