/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react'; // eslint-disable-line no-unused-vars
import ImageContainer from '../image-container';
import { Link } from '@reach/router';
import { useQueryParam } from 'use-query-params';
import Tags from '../tags';
import { ClipboardButton } from '../clipboard-button';

function SearchResults({ data, onClick, skipToCrop = false }) {
  const [productId] = useQueryParam('productid');
  const [groupId] = useQueryParam('groupid');

  const pimReference = productId || groupId;

  function linkTo(result) {
    if (productId) {
      return `/asset/${result.id}${
        skipToCrop ? '/crop' : ''
      }?productid=${pimReference}`;
    }

    if (groupId) {
      return `/asset/${result.id}${
        skipToCrop ? '/crop' : ''
      }?groupid=${pimReference}`;
    }

    return `/asset/${result.id}${skipToCrop ? '/crop' : ''}`;
  }

  return (
    <ul
      css={css`
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0 -15px;
        padding: 15px 0;
        position: relative;

        & + & {
          margin-top: -30px;
        }

        .copy {
          position: absolute;
          z-index: 10;
          right: 10px;
          top: 10px;
        }
      `}
    >
      {data.map((result) => (
        <li
          css={css`
            align-self: flex-start;
            color: black;
            cursor: pointer;
            margin: 15px;
            position: relative;
            width: calc(25% - 30px);
          `}
          key={result.id}
        >
          <div
            css={css`
              box-shadow: 0 0 8px #888888;
            `}
          >
            <Link
              css={css`
                text-decoration: none;
              `}
              onClick={(e) => onClick(e, result)}
              to={linkTo(result)}
            >
              <ImageContainer>
                <img src={result.thumbnails.thul} alt={result.name} />
              </ImageContainer>
              {result.name && (
                <div
                  css={css`
                    color: rgb(75, 85, 99);
                    font-size: 0.875rem;
                    overflow: hidden;
                    padding: 0.5rem;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    li:hover & {
                      overflow: visible;
                      white-space: normal;
                      word-break: break-all;
                    }
                  `}
                >
                  {result.name}
                </div>
              )}
            </Link>
          </div>
          <span className="copy">
            <ClipboardButton text={result.id} />
          </span>
          {result.roles.length > 0 && <Tags tags={result.roles} />}
        </li>
      ))}
    </ul>
  );
}

export default SearchResults;
