/** @jsxImportSource @emotion/react */
import { useSelect } from 'downshift';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import React from 'react'; // eslint-disable-line no-unused-vars
import { IoIosArrowDown } from 'react-icons/io';

function Select({
  align = 'left',
  children,
  defaultValue,
  disabled = false,
  onChange = () => {},
  options,
}) {
  const {
    getItemProps,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
    selectedItem,
  } = useSelect({
    initialSelectedItem: defaultValue,
    items: options,
    itemToString: (item) => (item ? item.value : ''),
    onSelectedItemChange: (item) => onChange(item.selectedItem),
  });

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <button
        css={css`
          appereance: none;
          background-color: #bbd03a;
          border: none;
          border-radius: 0;
          color: #fff;
          cursor: pointer;
          font-size: 14px;
          padding: 13px 18px;
          text-align: left;
        `}
        disabled={disabled}
        type="button"
        {...getToggleButtonProps()}
      >
        <span
          css={css`
            align-items: center;
            display: flex;
          `}
        >
          {(selectedItem ? selectedItem.label : '') || children}
          <i
            css={css`
              height: 1em;
              margin-left: 18px;
            `}
          >
            <IoIosArrowDown />
          </i>
        </span>
      </button>
      {
        <ul
          css={css`
            background-color: #bbd03a;
            color: #fff;
            margin: 0;
            max-height: 18.75rem;
            min-width: 14rem;
            padding: ${isOpen ? '0.25rem' : 0};
            position: absolute;
            ${align === 'right' ? 'right: 0;' : null};
            overflow-y: auto;
            width: 100%;
            z-index: 1000;
          `}
          {...getMenuProps()}
        >
          {isOpen &&
            options.map((option, index) => (
              <li
                style={
                  highlightedIndex === index
                    ? {
                        backgroundColor: '#fff',
                        color: '#bbd03a',
                        padding: '5px',
                      }
                    : { padding: '5px' }
                }
                key={`${option.value}${index}`}
                {...getItemProps({ disabled: disabled, item: option, index })}
              >
                {option.label}
              </li>
            ))}
        </ul>
      }
    </div>
  );
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
};

export default Select;
