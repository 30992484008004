/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link, navigate } from '@reach/router';
import prettyBytes from 'pretty-bytes';
import React, { useMemo } from 'react'; // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify';
import { useQueryParam } from 'use-query-params';
import { fetchMedia, fetchSimilarMedia } from '../../lib/client-api';
import Button from '../../components/button';
import { ClipboardButton } from '../../components/clipboard-button';
import ImageContainer from '../../components/image-container';
import Layout from '../../components/layout';
import Placeholder from '../../components/placeholder';
import { useQuery } from 'react-query';
import { AssetUpload } from '../../components/asset-upload';
import { MultiSelect } from '../../components/multi-select/multi-select';
import { LOCALIZATION } from '../../config/localization';
import Tags from '../../components/tags';
import { useLocale } from '../../context/locale-context';

const Toolbar = ({ asset, existingLocales = [] }) => {
  const { locales, setLocales } = useLocale();
  const [productId] = useQueryParam('productid');
  const [groupId] = useQueryParam('groupid');

  const pimReference = productId || groupId;

  const availableLocales = LOCALIZATION.filter(
    (item) => !existingLocales.includes(item.value)
  );

  function handleNewPick() {
    if (locales.length === 0) {
      toast('Please select a locale first.');

      return false;
    }

    if (productId) {
      navigate(`${asset.id}/picker?productid=${pimReference}`);
    } else if (groupId) {
      navigate(`${asset.id}/picker?groupid=${pimReference}`);
    } else {
      navigate(`${asset.id}/picker`);
    }
  }

  function handleLocalesChange(selectedLocales) {
    setLocales(selectedLocales);
  }

  return (
    <div
      css={css`
        align-items: center;
        display: flex;
        justify-content: space-between;
      `}
    >
      <ul
        css={css`
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            line-height: 1.5;
          }

          li + li {
            margin-top: 1rem;
          }
        `}
      >
        {asset?.name && <li>{asset.name}</li>}
        <li
          css={css`
            align-items: center;
            display: flex;

            .copy {
              margin-left: 0.25rem;
            }
          `}
        >
          {asset.id}{' '}
          <span className="copy">
            <ClipboardButton text={asset.id} theme="dark" />
          </span>
        </li>
        <li>{prettyBytes(asset.fileSize)}</li>
      </ul>
      <div
        css={css`
          display: flex;

          > * + * {
            margin-left: 30px;
          }
        `}
      >
        <Button onClick={handleNewPick}>Pick new locale</Button>
        <MultiSelect onChange={handleLocalesChange} options={availableLocales}>
          Locales
        </MultiSelect>
      </div>
    </div>
  );
};

const RelatedAssets = ({
  assetIsLoading,
  assets,
  masterId,
  onAssetClick = () => {},
  onUploadSuccess = () => {},
  onUploadStart = () => {},
}) => {
  const [productId] = useQueryParam('productid');
  const [groupId] = useQueryParam('productid');

  const pimReference = productId || groupId;

  const { locales, setLocales } = useLocale();

  function handleUploadStart(uppy) {
    if (locales.length === 0) {
      toast('Please select a locale first.');

      uppy.cancelAll();

      return false;
    }

    onUploadStart();
  }

  function linkTo(item) {
    if (productId) {
      return `/asset/${item.id}/crop?productid=${pimReference}`;
    }

    if (groupId) {
      return `/asset/${item.id}/crop?groupid=${pimReference}`;
    }

    return `/asset/${item.id}/crop`;
  }

  return (
    <ul
      css={css`
        list-style: none;
        margin: 0;
        padding: 0;
      `}
    >
      <li>
        <AssetUpload
          isOpen={true}
          inline={true}
          masterId={masterId}
          onUploadStart={handleUploadStart}
          onUploadSuccess={onUploadSuccess}
        />
      </li>
      {assetIsLoading ? (
        <li
          css={css`
            margin-top: 30px;
          `}
        >
          <Placeholder height={148} width={197} />
        </li>
      ) : (
        assets?.media?.map((item) => (
          <li
            css={css`
              margin-top: 30px;
            `}
            key={item.id}
          >
            <div
              css={css`
                box-shadow: 0 0 8px #888888;
                position: relative;
              `}
            >
              <Link
                onClick={() => {
                  setLocales([]);
                  onAssetClick();
                }}
                to={linkTo(item)}
              >
                <ImageContainer>
                  <img src={item.thumbnails.thul} alt="" />
                </ImageContainer>
              </Link>
            </div>
            {item.property_Localization && (
              <Tags inline tags={item.property_Localization} />
            )}
          </li>
        ))
      )}
    </ul>
  );
};

const SimilarMedia = ({ asset, assetIsLoading, pimReferenceIds }) => {
  const { data, error, isLoading } = useQuery(
    ['fetchSimilarMedia', pimReferenceIds],
    () => fetchSimilarMedia({ asset, pimReferenceIds }),
    {
      enabled: pimReferenceIds.length > 0,
    }
  );

  const [productId] = useQueryParam('productid');
  const [groupId] = useQueryParam('groupid');

  const pimReference = productId || groupId;

  if (!pimReferenceIds) {
    return null;
  }

  if (error) {
    return null;
  }

  function linkTo(item) {
    if (productId) {
      return `/asset/${item.id}?productid=${pimReference}`;
    }

    if (groupId) {
      return `/asset/${item.id}?groupid=${pimReference}`;
    }

    return `/asset/${item.id}`;
  }
  return (
    <ul
      css={css`
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          cursor: pointer;
        }

        li + li {
          margin-top: 30px;
        }
      `}
    >
      {(isLoading || assetIsLoading) && (
        <li>
          <Placeholder height={148} width={197} />
        </li>
      )}
      {!(isLoading || assetIsLoading) &&
        data &&
        data.map((item) => (
          <li
            key={item.id}
            css={css`
              box-shadow: 0 0 8px #888888;
              position: relative;
            `}
          >
            <Link to={linkTo(item)}>
              <ImageContainer>
                <img src={item.thumbnails.thul} alt="" />
              </ImageContainer>
            </Link>
          </li>
        ))}
    </ul>
  );
};

const Heading = styled.h2`
  background-color: #fff;
  color: #000;
  font-size: 16px;
  font-weight: normal;
  line-height: 18px;
  margin: 0;
  padding-bottom: 6px;
  padding-top: 6px;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export function AssetIndex({
  data,
  isLoading,
  masterId,
  mediaId,
  onAssetClick = () => {},
  onUploadSuccess = () => {},
  onUploadStart = () => {},
}) {
  const [productId] = useQueryParam('productid');
  const [groupId] = useQueryParam('groupid');

  const pimReference = productId || groupId;

  const { setLocales } = useLocale();

  const localizedAssetIds = useMemo(() => {
    if (!data?.relatedAssets) {
      return null;
    }

    const relatedAssets = data.relatedAssets.find(
      (meta) => meta.metaPropertyName === 'localization_relations'
    );

    return relatedAssets ? relatedAssets.assets : null;
  }, [data]);

  const {
    data: localizedAssets,
    isLoading: isLocalizedAssetsLoading,
  } = useQuery(
    ['fetchRelatedMedia', localizedAssetIds],
    () => fetchMedia({ search: { ids: localizedAssetIds } }),
    {
      enabled: !!localizedAssetIds,
    }
  );

  const existingLocales = useMemo(() => {
    if (!localizedAssets || !Array.isArray(localizedAssets?.media)) {
      return [];
    }

    let existing = [];

    localizedAssets.media.forEach((localizedAsset) => {
      if (!Array.isArray(localizedAsset.property_Localization)) {
        return;
      }

      existing = [...existing, ...localizedAsset.property_Localization];
    });

    return existing;
  }, [localizedAssets]);

  const similarMedia = useMemo(() => {
    if (!data || !data.property_PimReferences) {
      return [];
    }

    return data.property_PimReferences;
  }, [data]);

  function linkTo(data) {
    if (productId) {
      return `/asset/${data.id}/crop?productid=${pimReference}`;
    }

    if (groupId) {
      return `/asset/${data.id}/crop?groupid=${pimReference}`;
    }

    return `/asset/${data.id}/crop`;
  }

  return (
    <Layout
      title="Which locale do you want to use?"
      toolbar={
        data &&
        !isLoading && <Toolbar asset={data} existingLocales={existingLocales} />
      }
    >
      <article
        css={css`
          display: flex;
          height: 484px;
          padding-top: 30px;
        `}
      >
        <div
          css={css`
            padding-top: 30px;
            width: 100%;
          `}
        >
          {isLoading && <Placeholder width={570} height={428} />}
          {data && !isLoading && (
            <Link
              to={linkTo(data)}
              onClick={() => {
                setLocales([]);
              }}
            >
              <div
                css={css`
                  box-shadow: 0 0 8px #888888;
                  position: relative;
                `}
              >
                <ImageContainer>
                  <img src={data.thumbnails.webimage} alt="" />
                </ImageContainer>
              </div>
            </Link>
          )}
        </div>
        <aside
          css={css`
            flex: none;
            margin-left: 30px;
            overflow: auto;
            position: relative;
            width: 197px;
          `}
        >
          <Heading>Locales</Heading>
          <RelatedAssets
            assetIsLoading={isLocalizedAssetsLoading}
            assets={localizedAssets}
            masterId={mediaId}
            onAssetClick={onAssetClick}
            onUploadStart={onUploadStart}
            onUploadSuccess={onUploadSuccess}
          />
        </aside>
        <aside
          css={css`
            flex: none;
            margin-left: 30px;
            overflow: auto;
            position: relative;
            width: 197px;
          `}
        >
          <Heading>Same product</Heading>
          <SimilarMedia
            asset={data}
            assetIsLoading={isLoading}
            pimReferenceIds={similarMedia}
          />
        </aside>
      </article>
    </Layout>
  );
}
