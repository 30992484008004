/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Wrapper from '../wrapper';

export function PageTitle({ children }) {
  return (
    <h1
      css={css`
        background-color: #203965;
        color: #fff;
        font-size: 1rem;
        margin: 0;
        padding: 1rem;
      `}
    >
      <Wrapper>{children}</Wrapper>
    </h1>
  );
}
