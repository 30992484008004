export const LOCALIZATION = [
  {
    label: 'eng_glo',
    value: 'eng_glo',
  },
  {
    label: 'fra_glo',
    value: 'fra_glo',
  },
  {
    label: 'spa_glo',
    value: 'spa_glo',
  },
  {
    label: 'zho_glo',
    value: 'zho_glo',
  },
  {
    label: 'rus_glo',
    value: 'rus_glo',
  },
  {
    label: 'kor_glo',
    value: 'kor_glo',
  },
  {
    label: 'deu_deu',
    value: 'deu_deu',
  },
  {
    label: 'deu_aut',
    value: 'deu_aut',
  },
  {
    label: 'deu_che',
    value: 'deu_che',
  },
  {
    label: 'eng_usa',
    value: 'eng_usa',
  },
  {
    label: 'eng_gbr',
    value: 'eng_gbr',
  },
  {
    label: 'eng_sgp',
    value: 'eng_sgp',
  },
  {
    label: 'eng_glo',
    value: 'eng_glo',
  },
  {
    label: 'ita_ita',
    value: 'ita_ita',
  },
  {
    label: 'nld_nld',
    value: 'nld_nld',
  },
  {
    label: 'fra_fra',
    value: 'fra_fra',
  },
  {
    label: 'fra_che',
    value: 'fra_che',
  },
  {
    label: 'jpn_jpn',
    value: 'jpn_jpn',
  },
  {
    label: 'pol_pol',
    value: 'pol_pol',
  },
  {
    label: 'rus_rus',
    value: 'rus_rus',
  },
  {
    label: 'eng_glo',
    value: 'eng_glo',
  },
  {
    label: 'slk_svk',
    value: 'slk_svk',
  },
  {
    label: 'spa_esp',
    value: 'spa_esp',
  },
  {
    label: 'ces_cze',
    value: 'ces_cze',
  },
  {
    label: 'tur_tur',
    value: 'tur_tur',
  },
  {
    label: 'hun_hun',
    value: 'hun_hun',
  },
  {
    label: 'por_bra',
    value: 'por_bra',
  },
  {
    label: 'zho_twn',
    value: 'zho_twn',
  },
  {
    label: 'swe_swe',
    value: 'swe_swe',
  },
  {
    label: 'zho_chn',
    value: 'zho_chn',
  },
  {
    label: 'bul_bgr',
    value: 'bul_bgr',
  },
  {
    label: 'ron_rou',
    value: 'ron_rou',
  },
  {
    label: 'por_prt',
    value: 'por_prt',
  },
  {
    label: 'eng_chn',
    value: 'eng_chn',
  },
  {
    label: 'eng_can',
    value: 'eng_can',
  },
];
