/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import useCopy from '@react-hook/copy';

export function ClipboardButton({ text, theme = 'light' }) {
  const { copy } = useCopy(text);

  return (
    <button
      css={css`
        appearance: none;
        align-items: center;
        background-color: ${theme === 'light' ? '#fff' : 'transparent'};
        border: none;
        border-radius: 4px;
        color: ${theme === 'light' ? '#000' : '#fff'};
        cursor: pointer;
        display: flex;
        height: 1.5rem;
        padding: 0;
        justify-content: center;
        width: 1.5rem;

        &:hover {
          box-shadow: ${theme === 'light'
            ? '0 0 4px rgba(0, 0, 0, 0.2)'
            : 'none'};
        }
      `}
      onClick={copy}
    >
      <svg
        css={css`
          height: 1rem;
          width: 1rem;
        `}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
        ></path>
      </svg>
    </button>
  );
}
