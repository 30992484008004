/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react'; // eslint-disable-line no-unused-vars

function Tags({ inline = false, tags }) {
  return (
    <ul
      css={css`
        align-items: flex-end;
        display: flex;
        flex-direction: ${inline ? 'row' : 'column'};
        list-style: none;
        margin: 8px 0 0;
        padding: 0;
      `}
    >
      {tags.map((tag) => (
        <li
          css={css`
            background-color: #295173;
            border-radius: 2px;
            color: #fff;
            font-size: 12px;
            margin: ${inline ? '1.5px' : '3px 0 0'};
            padding: 2px 4px;
          `}
          key={tag}
        >
          {tag}
        </li>
      ))}
    </ul>
  );
}

export default Tags;
