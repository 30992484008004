/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import React from 'react'; // eslint-disable-line no-unused-vars
import AuthenticatedApp from './authenticated-app';
import UnauthenticatedApp from './unauthenticated-app';
import { useUser } from './context/user-context';
import 'react-toastify/dist/ReactToastify.minimal.css';
import './app.css';

function App() {
  const user = useUser();

  return (
    <React.Fragment>
      <Global
        styles={css`
          :root {
            --animate-duration: 300ms;
          }

          html {
            box-sizing: border-box;
          }

          *,
          *:before,
          *:after {
            box-sizing: inherit;
          }

          body {
            background-color: #fff;
            color: #000;
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
              Helvetica, Arial, sans-serif, 'Apple Color Emoji',
              'Segoe UI Emoji', 'Segoe UI Symbol';
            font-size: 16px;
            margin: 0;
          }

          img {
            height: auto;
            max-width: 100%;
            vertical-align: middle;
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }

            to {
              opacity: 1;
            }
          }

          .fadeIn {
            animation-name: fadeIn;
          }

          @keyframes fadeOut {
            from {
              opacity: 1;
            }

            to {
              opacity: 0;
            }
          }

          .fadeOut {
            animation-name: fadeOut;
          }
        `}
      />
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Fragment>
  );
}

export default App;
