import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AuthProvider } from './auth-context';
import { LocaleProvider } from './locale-context';
import { RoleProvider } from './role-context';
import { UserProvider } from './user-context';
import { ObjectTypeProvider } from './object-type-context';

const queryClient = new QueryClient();

function AppProviders({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <UserProvider>
          <LocaleProvider>
            <RoleProvider>
              <ObjectTypeProvider>{children}</ObjectTypeProvider>
            </RoleProvider>
          </LocaleProvider>
        </UserProvider>
      </AuthProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default AppProviders;
