/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react'; // eslint-disable-line no-unused-vars

function Wrapper({ children }) {
  return (
    <div
      css={css`
        margin-left: auto;
        margin-right: auto;
        width: 1024px;
      `}
    >
      {children}
    </div>
  );
}

export default Wrapper;
