const localStorageKey = '__trumpf_token__';

function getUser() {
  const token = getToken();

  if (!token) {
    return Promise.resolve(null);
  }

  return Promise.resolve({
    isAuthenticated: true
  });
}

function login(token) {
  window.localStorage.setItem(localStorageKey, JSON.stringify(token));
  return Promise.resolve();
}

function logout() {
  window.localStorage.removeItem(localStorageKey);
  return Promise.resolve();
}

function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

export { getToken, getUser, login, logout };
