/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Wrapper from './wrapper';

function Toolbar({ children }) {
  return (
    <div
      css={css`
        background-color: #295173;
        background-image: linear-gradient(rgb(32, 57, 101), rgb(41, 81, 115));
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 176px;
        padding-bottom: 30px;
        padding-top: 30px;
      `}
    >
      <Wrapper>{children}</Wrapper>
    </div>
  );
}

export default Toolbar;
