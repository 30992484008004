import React from 'react';
import { PageTitle } from './page-title';
import Toolbar from './toolbar';
import Wrapper from './wrapper';

const Layout = ({ children, title, toolbar }) => (
  <React.Fragment>
    {title && <PageTitle>{title}</PageTitle>}
    {toolbar && <Toolbar>{toolbar}</Toolbar>}
    <Wrapper>{children}</Wrapper>
  </React.Fragment>
);

export default Layout;
