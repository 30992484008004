/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect, useMemo } from 'react'; // eslint-disable-line no-unused-vars
import { toast } from 'react-toastify';
import Uppy from '@uppy/core';
import { DragDrop } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import { useQueryParam } from 'use-query-params';
import { getToken } from '../lib/auth-client';
import { useRole } from '../context/role-context';

import '@uppy/core/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import LoadingIndicator from './loading-indicator';
import { useLocale } from '../context/locale-context';

const token = getToken();

export function AssetUpload({
  inline = false,
  isOpen = false,
  masterId,
  onDragLeave = () => {},
  onUploadStart = () => {},
  onUploadSuccess = () => {},
}) {
  const { role } = useRole();
  const { locales } = useLocale();
  const [productId] = useQueryParam('productid');
  const [groupId] = useQueryParam('groupid');

  const pimReference = productId || groupId;

  const uppy = useMemo(() => {
    return Uppy({
      restrictions: {
        allowedFileTypes: ['image/*'],
        maxFileSize: 20971520,
        maxNumberOfFiles: 1,
      },
      autoProceed: true,
    }).use(XHRUpload, {
      endpoint: `${process.env.REACT_APP_API_URL}/api/assets`,
      fieldName: 'image',
      timeout: 0,
    });
  }, []);

  useEffect(() => {
    if (!role) {
      return;
    }

    uppy.on('file-added', () => {
      uppy.setMeta({
        token,
        role: role?.value,
        locales: locales.map((locale) => locale.value),
      });

      if (pimReference) {
        uppy.setMeta({
          productId: pimReference,
        });
      }

      if (masterId) {
        uppy.setMeta({
          masterId,
        });
      }
    });
  }, [locales, masterId, pimReference, role, uppy]);

  // const handleUploadStart = React.useCallback(
  //   function () {
  //     const result = onUploadStart(uppy);

  //     if (result === false) {
  //       return false;
  //     }

  //     toast(
  //       <React.Fragment>
  //         <span
  //           css={css`
  //             align-items: center;
  //             display: flex;
  //           `}
  //         >
  //           <LoadingIndicator /> Uploading...
  //         </span>
  //       </React.Fragment>,
  //       {
  //         autoClose: false,
  //       }
  //     );
  //   },
  //   [onUploadStart]
  // );

  const handleUploadError = React.useCallback(
    function (file, error, response) {
      const errors = response.body.errors;

      let errorMsg = [];

      Object.keys(errors).forEach((key) => {
        errorMsg.push(`${key}: ${errors[key]}`);
      });

      toast.dismiss();

      toast(
        <React.Fragment>
          Upload error:
          <br />
          {errorMsg.join('\n')}
        </React.Fragment>
      );

      uppy.cancelAll();
    },
    [uppy]
  );

  useEffect(() => {
    function handleUploadStart() {
      const result = onUploadStart(uppy);

      if (result === false) {
        return false;
      }

      toast(
        <React.Fragment>
          <span
            css={css`
              align-items: center;
              display: flex;
            `}
          >
            <LoadingIndicator /> Uploading...
          </span>
        </React.Fragment>,
        {
          autoClose: false,
        }
      );
    }

    uppy.on('upload', handleUploadStart);

    return () => {
      uppy.off('upload', handleUploadStart);
    };
  }, [onUploadStart, uppy]);

  useEffect(() => {
    uppy.on('upload-error', handleUploadError);

    return () => {
      uppy.off('upload-error', handleUploadError);
    };
  }, [handleUploadError, uppy]);

  useEffect(() => {
    function handleUploadSuccess(file, response) {
      return onUploadSuccess(response.body.id);
    }

    uppy.on('upload-success', handleUploadSuccess);

    return () => {
      uppy.off('upload-success', handleUploadSuccess);
    };
  }, [onUploadSuccess, uppy]);

  if (!role) {
    return (
      <div
        css={css`
          background-color: #fff;
          color: #000;
          padding-top: 30px;
          text-align: center;
        `}
      >
        To upload a file please select a role first
      </div>
    );
  }

  return (
    <div
      onDragLeave={onDragLeave}
      css={css`
        background-color: ${inline ? 'transparent' : 'rgba(0, 0, 0, 0.8)'};
        display: ${isOpen ? 'block' : 'none'};
        height: ${inline ? '148px' : '100vh'};
        left: 0;
        position: ${inline ? 'static' : 'fixed'};
        top: 0;
        width: ${inline ? '100%' : '100vw'};
        z-index: 100;

        > div {
          height: 100%;
          padding: ${inline ? '0' : '100px'};
        }

        .uppy-DragDrop-container {
          background-color: transparent;
          border-radius: 10px;
          border: 1px dashed ${inline ? 'rgba(0, 0, 0, 0.8)' : '#fff'};
        }

        .uppy-DragDrop-label {
          color: ${inline ? 'rgba(0, 0, 0, 0.8)' : '#fff'};
          font-size: inherit;
          margin-bottom: 0;
        }

        .uppy-DragDrop-browse {
          color: ${inline ? 'rgba(0, 0, 0, 0.8)' : '#fff'};
        }

        .uppy-DragDrop-inner {
          padding: ${inline ? '20px' : '80px 20px'};
        }

        .uppy-DragDrop-arrow {
          height: ${inline ? '32px' : '60px'};
          width: ${inline ? '32px' : '60px'};
        }
      `}
    >
      <DragDrop height="100%" uppy={uppy}></DragDrop>
    </div>
  );
}
