/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import Tags from '@yaireo/tagify/dist/react.tagify';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { fetchProducts, fetchProductCategories } from '../lib/client-api';
import Button from './button';
import LoadingIndicator from './loading-indicator';

import '@yaireo/tagify/dist/tagify.css';

const JOB_OPTIONS = [

  {

    label: 'Generierung Preislistenvorschaudokument',
    value: 'generatePricelistPreview',

  },

  {

    label: 'Generierung Plattformpreislistenvorschaudokument',
    value: 'generatePlatformPricelistPreview',

  },

  {

    label: 'Generierung Angebotsvorschaudokument',
    value: 'generateQuotePreview',

  },

  {

    label: 'Generierung Angebotspreislistenvorschaudokument',
    value: 'generateQuotePricelistPreviewDocument',

  },

];

const MARKET_OPTIONS = [
  {
    label: 'bul_bgr',
    value: 'bul_bgr',
  },
  {
    label: 'ces_cze',
    value: 'ces_cze',
  },
  {
    label: 'deu_deu',
    value: 'deu_deu',
  },
  {
    label: 'eng_gbr',
    value: 'eng_gbr',
  },
  {
    label: 'eng_glo',
    value: 'eng_glo',
  },
  {
    label: 'fra_fra',
    value: 'fra_fra',
  },
  {
    label: 'hun_hun',
    value: 'hun_hun',
  },
  {
    label: 'ita_ita',
    value: 'ita_ita',
  },
  {
    label: 'jpn_jpn',
    value: 'jpn_jpn',
  },
  {
    label: 'nld_nld',
    value: 'nld_nld',
  },
  {
    label: 'pol_pol',
    value: 'pol_pol',
  },
  {
    label: 'rus_rus',
    value: 'rus_rus',
  },
  {
    label: 'slk_svk',
    value: 'slk_svk',
  },
  {
    label: 'spa_esp',
    value: 'spa_esp',
  },
  {
    label: 'por_bra',
    value: 'por_bra',
  },
  {
    label: 'por_prt',
    value: 'por_prt',
  },
  {
    label: 'ron_rou',
    value: 'ron_rou',
  },
  {
    label: 'swe_swe',
    value: 'swe_swe',
  },
  {
    label: 'tur_tur',
    value: 'tur_tur',
  },
  {
    label: 'zho_chn',
    value: 'zho_chn',
  },
  {
    label: 'zho_twn',
    value: 'zho_twn',
  },
];

const HEADLINE_OPTIONS = [
  {
    label: 'Dateiübertragung',
    value: '90000123',
  },
  {
    label: 'Disposemaster',
    value: '9000124',
  },
];

function FormItem({ children, description }) {
  return (
    <div
      css={css`
        margin-bottom: 2rem;
      `}
    >
      {children}

      {description && (
        <p
          css={css`
            margin: 0.25rem 0 0;
            font-size: 0.75rem;
          `}
        >
          {description}
        </p>
      )}
    </div>
  );
}

function FormLabel({ children, htmlFor }) {
  return (
    <label
      css={css`
        display: block;
        margin-bottom: 0.25rem;
      `}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
}

export function PriceListForm({ id, onSubmit }) {
  const [isPending, setIsPending] = useState(false);
  const { data, isLoading } = useQuery(['fetchProducts', id], () =>
    fetchProducts({ id })
  );
  const { data: productCategories } = useQuery(['fetchProductCategories', id], () =>
    fetchProductCategories({ id })
  );


  console.log("productCategories");
  console.dir(productCategories);

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      task: 'generatePricelistPreview',
      market: 'deu_deu',
    },
  });

  const job = watch('job');

  useEffect(() => {
    register('emailRecipients');
  }, [register]);

  function handleEmailRecipientsInput(e) {
    const emails = e.detail.tagify.getCleanValue().map(({ value }) => value);
    setValue('emailRecipients', emails);
  }

  function handleFormSubmit(event) {
    setIsPending(true);

    setTimeout(() => {
      setIsPending(false);
    }, 60000);

    return handleSubmit(onSubmit)(event);
  }

  return (
    <form
      css={css`
        padding-bottom: 4rem;
        padding-top: 4rem;
      `}
      onSubmit={handleFormSubmit}
    >
      <FormItem>
        <FormLabel htmlFor="job">Aufgabe</FormLabel>
        <select
          css={css`
            border: 1px solid #000;
            border-radius: 0;
            font-size: 0.875rem;
            height: 2rem;
            padding: 0.25rem;

            &:focus {
              outline: none;
              border-color: #0847c1;
            }
          `}
          id="job"
          name="job"
          ref={register}
        >
          {JOB_OPTIONS.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </FormItem>
      <FormItem>
        <FormLabel htmlFor="market">Markt und Sprache</FormLabel>
        <select
          css={css`
            border: 1px solid #000;
            border-radius: 0;
            font-size: 0.875rem;
            height: 2rem;
            padding: 0.25rem;

            &:focus {
              outline: none;
              border-color: #0847c1;
            }
          `}
          id="market"
          name="market"
          ref={register}
        >
          {MARKET_OPTIONS.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </FormItem>

      <FormItem
        description="Es können nur E-Mailadressen innerhalb der trumpf.com Domain
            verwendet werden."
      >
        <FormLabel htmlFor="email">Email(s)</FormLabel>
        <Global
          styles={css`
            .tagify {
              --tags-border-color: #000;
              --tags-hover-border-color: #000;
              --tags-focus-border-color: #0847c1;
              --tag-pad: 0.25rem;
              font-size: 0.875rem;
              line-height: 1.125rem;
            }

            .tagify__input {
              margin: 0.125rem;
            }
          `}
        />
        <Tags
          style={{
            '--tags-border-color': '#000',
          }}
          name="emailRecipients"
          onChange={handleEmailRecipientsInput}
          settings={{
            validate: ({ value }) => {
              const regex = /^[a-z0-9._-].*@trumpf.com$/i;

              return Array.isArray(value.match(regex)) ? true : false;
            },
          }}
        />
      </FormItem>

      {(job === 'generateQuotePreview' ||
        job === 'generateQuotePricelistPreviewDocument') &&
        false && (
          <FormItem>
            <FormLabel htmlFor="headlines">Überschriften</FormLabel>
            <select
              css={css`
                border: 1px solid #000;
                border-radius: 0;
                font-size: 0.875rem;
                padding: 0.25rem;

                &:focus {
                  outline: none;
                  border-color: #0847c1;
                }
              `}
              multiple
              name="includeHeadlineMaterials"
              ref={register}
            >
              {HEADLINE_OPTIONS.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  selected="selected"
                >
                  {option.label}
                </option>
              ))}
            </select>
          </FormItem>
        )}

      {job === 'generatePlatformPricelistPreview' && data?.products && (
        <FormItem>
          <FormLabel htmlFor="machines">Maschinenauswahl</FormLabel>
          {data.products.map(({ id, title }) => {
            return (
              <div key={id}>
                <label>
                  <input
                    type="checkbox"
                    value={id}
                    name="machines"
                    ref={register}
                  />{' '}
                  {title}
                </label>
              </div>
            );
          })}
        </FormItem>
      )}
      <FormItem>
        <FormLabel htmlFor="price-list-number">Preislistenversion</FormLabel>
        <input
          css={css`
            border: 1px solid #000;
            border-radius: 0;
            font-size: 0.875rem;
            height: 2rem;
            padding: 0.25rem;

            &:focus {
              outline: none;
              border-color: #0847c1;
            }
          `}
          id="price-list-number"
          type="text"
          name="pricelistNumber"
          ref={register}
        />
      </FormItem>

      {(job === 'generateQuotePreview' ||
        job === 'generateQuotePricelistPreviewDocument') && (
        <FormItem>
          <FormLabel>
            <input
              type="checkbox"
              name="exportPriceInformations"
              ref={register}
            />{' '}
            Preisinformationen mit exportieren
          </FormLabel>
        </FormItem>
      )}

      {productCategories?.products && (job == 'generateQuotePricelistPreviewDocument' && ( !(productCategories?.products[0].hierarchyUids.includes('11902-8FXC-PROD') || productCategories?.products[0].hierarchyUids.includes('11901-8FXC-PROD')) ) || job != 'generateQuotePricelistPreviewDocument'  )&& (
      <FormItem>
        <FormLabel htmlFor="price-list-number">Preislistenversion Vergleich</FormLabel>
        <input
          css={css`
            border: 1px solid #000;
            border-radius: 0;
            font-size: 0.875rem;
            height: 2rem;
            padding: 0.25rem;

            &:focus {
              outline: none;
              border-color: #0847c1;
            }
          `}
          id="reference-document"
          type="text"
          name="referenceDocument"
          ref={register}
        />
      </FormItem>
      )}

      <div>
	<Button
	  disabled={isPending}
          icon={isPending && <LoadingIndicator />}
          type="submit"
        >
          Dokument erzeugen
        </Button>
      </div>
    </form>
  );
}
