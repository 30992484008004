import { ClassNames, css } from '@emotion/react';
import { Location, Redirect, Router } from '@reach/router';
import qs from 'qs';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from './context/auth-context';
import Wrapper from './components/wrapper';
import { PriceList } from './screens/price-list';
import { RequestMaterialNumber } from './screens/requestmaterialnumber';
import { parseUrl } from './lib/utils';
import { ToastContainer, cssTransition } from 'react-toastify';
import { IoIosCloseCircleOutline } from 'react-icons/io';

const fadeTransition = cssTransition({
  enter: 'animate__animated animate__fadeInDown',
  exit: 'animate__animated animate__fadeOutUp',
});

const CloseButton = ({ closeToast }) => (
  <i
    css={css`
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
    `}
    onClick={closeToast}
  >
    <IoIosCloseCircleOutline />
  </i>
);

const getAuthorizationUrl = async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/auth/authorization_url`;
  const { protocol, host, pathname, search } = parseUrl(window.location.href);
  const redirectUri = `${protocol}//${host}${pathname}authorize`;

  window.localStorage.setItem('query', search);

  const res = await fetch(
    `${endpoint}?redirectUri=${encodeURIComponent(redirectUri)}`,
    {
      method: 'POST',
    }
  );

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

const getToken = async (code) => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/auth/token/${code}`;

  const res = await fetch(endpoint);

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

function Login() {
  const { data } = useQuery('getAuthorizationUrl', getAuthorizationUrl);

  useEffect(() => {
    if (data) {
      window.location = data.authorizationUrl;
    }
  }, [data]);

  return null;
}

function TokenExchange({ code }) {
  const { data, isLoading } = useQuery(['getToken', code], () =>
    getToken(code)
  );

  const { login } = useAuth();

  useEffect(() => {
    if (data) {
      login(data.token);
    }
  }, [data, login]);

  if (isLoading) {
    return null;
  }

  const search = window.localStorage.getItem('query');
  window.localStorage.removeItem('query');

  return <Redirect noThrow to={`/${search}`} />;
}

function Authorize() {
  return (
    <Location>
      {({ location }) => {
        const params = qs.parse(location.search, { ignoreQueryPrefix: true });

        if (!params.code) {
          return <Redirect noThrow to="/" />;
        }

        return <TokenExchange code={params.code} />;
      }}
    </Location>
  );
}

function UnauthenticatedApp() {
  return (
    <Wrapper>
     <ClassNames>
        {({ css }) => (
          <ToastContainer
            autoClose={5000}
            className={css({
              position: 'fixed',
              zIndex: 9999,
              WebkitTransform: 'translate3d(0, 0, 9999px)',
              width: '320px',
              top: '1em',
              left: '50%',
              marginLeft: '-160px',
            })}
            closeOnClick={true}
            closeButton={<CloseButton />}
            draggable={false}
            hideProgressBar={true}
            pauseOnFocusLoss={false}
            position="top-center"
            toastClassName={css({
              alignItems: 'center',
              backgroundColor: 'rgb(41, 81, 115)',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              padding: '30px',
              position: 'relative',
              boxShadow: '0 0 8px #888888',
            })}
            transition={fadeTransition}
          />
        )}
      </ClassNames>
      <Router>
        <Login path="/" />
        <Authorize path="/authorize" />
        <PriceList path="/price-list" />
	<RequestMaterialNumber path="/workflow-request-matnum" />
      </Router>
    </Wrapper>
  );
}

export default UnauthenticatedApp;
