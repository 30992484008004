import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { savePriceList } from '../lib/client-api';
import Layout from '../components/layout';
import { PriceListForm } from '../components/price-list-form';
import { useQueryParam } from 'use-query-params';

export function PriceList() {
  const [epimId] = useQueryParam('id');
  const [userId] = useQueryParam('userid');
  const mutation = useMutation(
    (data) => {
      const priceListData = {
        ...data,
        epimId,
        userId,
      };

      return savePriceList(priceListData);
    },
    {
      onSuccess: () => {
        toast('Dokumenterzeugung wurde gestartet');
      },
    }
  );

  function handleSubmit(data) {
    mutation.mutate(data);
  }

  return (
    <Layout title="Workflow">
      <PriceListForm id={epimId} onSubmit={handleSubmit} />
    </Layout>
  );
}
