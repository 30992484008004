/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useEffect } from 'react'; // eslint-disable-line no-unused-vars
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useRole } from '../../context/role-context';
import Select from '../select';
import Button from '../button';
import { LOCALIZATION } from '../../config/localization';

const fetchRoles = async () => {
  const endpoint = `${process.env.REACT_APP_API_URL}/api/derivatives/roles`;
  const res = await fetch(endpoint, {
    method: 'GET',
  });

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

const SearchForm = ({
  hideRoleSelect = false,
  onImageStyleChange = () => {},
  onSearch = () => {},
}) => {
  const { data: roles } = useQuery('fetchRoles', fetchRoles, {
    enabled: !hideRoleSelect,
  });
  const { register, handleSubmit, setValue } = useForm();
  const { role, setRole } = useRole();

  function handleTypeChange(type) {
    setValue('type', type.value);
  }

  function handleLocaleChange({ value }) {
    setValue('locale', value);
  }

  useEffect(() => {
    register({ name: 'type' });
    register({ name: 'locale' });
  }, [register]);

  return (
    <form onSubmit={handleSubmit(onSearch)}>
      <div
        css={css`
          margin-bottom: 30px;
        `}
      >
        <input
          css={css`
            appereance: none;
            background-color: #fff;
            border: none;
            color: #000;
            font-size: 14px;
            padding: 13px 18px;

            ::placeholder {
              color: #727272;
              opacity: 1;
            }
          `}
          name="keyword"
          placeholder="Search"
          ref={register}
        />
      </div>
      <div
        css={css`
          display: flex;

          > * + * {
            margin-left: 30px;
          }
        `}
      >
        <Select
          options={[
            {
              label: 'Public web',
              value: 'web',
            },
            { label: 'Quoting', value: 'quote' },
          ]}
        >
          Use
        </Select>
        <Select
          onChange={handleTypeChange}
          options={[
            {
              label: 'Image',
              value: 'image',
            },
            { label: 'Video', value: 'video' },
            { label: 'PDF', value: 'document' },
          ]}
        >
          Type
        </Select>
        <Select onChange={handleLocaleChange} options={LOCALIZATION}>
          Language
        </Select>
        {!hideRoleSelect ? (
          <div
            css={css`
              margin-left: auto;
            `}
          >
            {roles && (
              <Select
                align="right"
                defaultValue={role}
                onChange={(item) => {
                  setRole(item);
                  toast.dismiss();
                }}
                options={roles.roles.map(({ label, value, hasFormat }) => ({
                  label,
                  value,
                  hasFormat,
                }))}
              >
                Role
              </Select>
            )}
          </div>
        ) : null}
        <Button onClick={() => {}} type="submit">
          Search
        </Button>
      </div>
    </form>
  );
};

export default SearchForm;
