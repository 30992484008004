/** @jsxImportSource @emotion/react */
import { useSelect } from 'downshift';
import { css } from '@emotion/react';
import React, { useState } from 'react'; // eslint-disable-line no-unused-vars
import { IoIosArrowDown } from 'react-icons/io';

function stateReducer(state, actionAndChanges) {
  const { changes, type } = actionAndChanges;
  switch (type) {
    case useSelect.stateChangeTypes.InputKeyDownEnter:
    case useSelect.stateChangeTypes.ItemClick:
      return {
        ...changes,
        isOpen: true, // keep menu open after selection.
        highlightedIndex: state.highlightedIndex,
        inputValue: '', // don't add the item string as input value at selection.
      };
    case useSelect.stateChangeTypes.InputBlur:
      return {
        ...changes,
        inputValue: '', // don't add the item string as input value at selection.
      };
    default:
      return changes;
  }
}

export function MultiSelect({
  children,
  disabled = false,
  onChange = () => {},
  options,
}) {
  const [selectedItems, setSelectedItems] = useState([]);

  const {
    getItemProps,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    isOpen,
  } = useSelect({
    items: options,
    itemToString: (item) => (item ? item.value : ''),
    selectedItem: null,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) {
        return;
      }
      const index = selectedItems.indexOf(selectedItem);

      let newSelectedItems = [];

      if (index > 0) {
        newSelectedItems = [
          ...selectedItems.slice(0, index),
          ...selectedItems.slice(index + 1),
        ];
      } else if (index === 0) {
        newSelectedItems = [...selectedItems.slice(1)];
      } else {
        newSelectedItems = [...selectedItems, selectedItem];
      }

      setSelectedItems(newSelectedItems);

      onChange(newSelectedItems);
    },
    stateReducer,
  });

  const countText = selectedItems.length ? ` (${selectedItems.length})` : '';

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <button
        css={css`
          appereance: none;
          background-color: #bbd03a;
          border: none;
          border-radius: 0;
          color: #fff;
          cursor: pointer;
          font-size: 14px;
          padding: 13px 18px;
          text-align: left;
        `}
        disabled={disabled}
        type="button"
        {...getToggleButtonProps()}
      >
        <span
          css={css`
            align-items: center;
            display: flex;
          `}
        >
          {`${children}${countText}`}
          <i
            css={css`
              height: 1em;
              margin-left: 18px;
            `}
          >
            <IoIosArrowDown />
          </i>
        </span>
      </button>
      {
        <ul
          css={css`
            background-color: #bbd03a;
            color: #fff;
            margin: 0;
            max-height: 200px;
            min-width: 200px;
            padding: 0;
            position: absolute;
            overflow-y: auto;
            width: 100%;
            z-index: 1000;
          `}
          {...getMenuProps()}
        >
          {isOpen &&
            options.map((option, index) => (
              <li
                css={{
                  alignItems: 'center',
                  backgroundColor:
                    highlightedIndex === index ? '#fff' : 'transparent',
                  color: highlightedIndex === index ? '#bbd03a' : 'inherit',
                  cursor: 'pointer',
                  display: 'flex',
                  padding: '5px',
                }}
                key={`${option.value}${index}`}
                {...getItemProps({ disabled: disabled, item: option, index })}
              >
                <input
                  css={css`
                    margin-right: 4px;
                  `}
                  type="checkbox"
                  checked={selectedItems.includes(option)}
                  value={option}
                  onChange={() => {}}
                />
                {option.label}
              </li>
            ))}
        </ul>
      }
    </div>
  );
}
