import React, { useContext } from 'react';
import { useQueryParam, NumberParam, withDefault } from 'use-query-params';

const ObjectTypeContext = React.createContext();

function ObjectTypeProvider(props) {
  const [objectTypeParam] = useQueryParam(
    'objecttype',
    withDefault(NumberParam, 2020)
  );

  return <ObjectTypeContext.Provider value={objectTypeParam} {...props} />;
}

function useObjectType() {
  const context = useContext(ObjectTypeContext);

  if (context === undefined) {
    throw new Error('useObjectType must be used within an ObjectTypeProvider');
  }

  return context;
}

export { ObjectTypeProvider, useObjectType };
