/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react'; // eslint-disable-line no-unused-vars

function LoadingIndicator({
  color = [255, 255, 255],
  height = '2em',
  width = '2em',
}) {
  const colorRgb = color.join(',');

  return (
    <div
      css={css`
        animation: spin 1.1s infinite linear;
        border-top: 2px solid rgba(${colorRgb}, 0.2);
        border-right: 2px solid rgba(${colorRgb}, 0.2);
        border-bottom: 2px solid rgba(${colorRgb}, 0.2);
        border-left: 2px solid rgb(${colorRgb});
        border-radius: 50%;
        display: inline-block;
        font-size: 10px;
        margin-right: 10px;
        height: ${height};
        position: relative;
        text-indent: -9999em;
        transform: translateZ(0);
        width: ${width};

        &:after {
          border-radius: 50%;
          height: ${height};
          width: ${width};
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}
    >
      Loading...
    </div>
  );
}

export default LoadingIndicator;
