/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import Tags from '@yaireo/tagify/dist/react.tagify';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from './button';
import LoadingIndicator from './loading-indicator';

import '@yaireo/tagify/dist/tagify.css';

function FormItem({ children, description }) {
  return (
    <div
      css={css`
        margin-bottom: 2rem;
      `}
    >
      {children}

      {description && (
        <p
          css={css`
            margin: 0.25rem 0 0;
            font-size: 0.75rem;
          `}
        >
          {description}
        </p>
      )}
    </div>
  );
}

function FormLabel({ children, htmlFor }) {
  return (
    <label
      css={css`
        display: block;
        margin-bottom: 0.25rem;
      `}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
}

export function RequestMaterialNumberForm({ onSubmit }) {
  const [isPending, setIsPending] = useState(false);
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
    },
  });

  function handleFormSubmit(event) {
    setIsPending(true);

    setTimeout(() => {
      setIsPending(false);
    }, 60000);

    return handleSubmit(onSubmit)(event);
  }

  return (
    <form
      css={css`
        padding-bottom: 4rem;
        padding-top: 4rem;
      `}
      onSubmit={handleFormSubmit}
    >
      <div>
        <Button disabled={isPending} icon={isPending && <LoadingIndicator />} type="submit">Materialnummer anfordern</Button>
      </div>
    </form>
  );
}
