import styled from '@emotion/styled';

const ImageContainer = styled.span`
  display: block;
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-top: 75%;
  }

  img {
    height: 100%;
    object-fit: contain;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`;

export default ImageContainer;
