/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react'; // eslint-disable-line no-unused-vars
import { IconContext } from 'react-icons';

const Button = ({ children, icon, onClick, ...props }) => (
  <IconContext.Provider
    value={{
      size: '16px',
      style: {
        position: 'absolute',
        right: '5px',
        top: '5px',
      },
    }}
  >
    <button
      css={css`
        appereance: none;
        align-items: center;
        background-color: #bbd03a;
        border: none;
        border-radius: 0;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        overflow: hidden;
        padding: 13px;
        position: relative;
      `}
      onClick={onClick}
      {...props}
    >
      {icon}
      <span>{children}</span>
    </button>
  </IconContext.Provider>
);

export default Button;
