/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react'; // eslint-disable-line no-unused-vars

function Placeholder({ absolute, height, width }) {
  return (
    <div
      css={css`
        height: 100%;
        overflow: hidden;
        position: ${absolute ? 'absolute' : 'relative'};
        width: 100%;

        &::before {
          content: ' ';
          position: absolute;
          height: 200%;
          top: 50%;
          right: 0;
          bottom: 0;
          left: 50%;
          z-index: 1;
          width: 500%;
          margin-left: -250%;
          margin-top: -100%;
          animation: phAnimation 1s linear infinite;
          background: linear-gradient(
              to right,
              rgba(255, 255, 255, 0) 40%,
              rgba(255, 255, 255, 0.35) 50%,
              rgba(255, 255, 255, 0) 60%
            )
            50% 50%;
        }

        @keyframes phAnimation {
          0% {
            transform: translate3d(-30%, 0, 0) rotate(5deg);
          }
          100% {
            transform: translate3d(30%, 0, 0) rotate(5deg);
          }
        }
      `}
    >
      <svg
        css={css`
          fill: #ecebeb;
        `}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width={width} height={height} />
      </svg>
    </div>
  );
}

export default Placeholder;
