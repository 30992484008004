import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { saveRequestMaterialNumber } from '../lib/client-api';
import Layout from '../components/layout';
import { RequestMaterialNumberForm } from '../components/requestmaterialnumber-form';
import { useQueryParam } from 'use-query-params';

export function RequestMaterialNumber() {
  const [epimId] = useQueryParam('id');
  const [userId] = useQueryParam('userid');
  const mutation = useMutation(
    (data) => {
      const priceListData = {
        ...data,
        epimId,
        userId,
      };

      return saveRequestMaterialNumber(priceListData);
    },
    {
      onSuccess: () => {
        toast('Prozess gestartet.   ');
      },
      onFailure: () => {
        toast('Der Prozess wurde gestartet, jedoch wurde ein Fehler zurückgemeldet.');
      },
    }
  );

  function handleSubmit(data) {
    mutation.mutate(data);
  }

  return (
    <Layout title="Materialnummer anfordern">
      <RequestMaterialNumberForm onSubmit={handleSubmit} />
    </Layout>
  );
}
