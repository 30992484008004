import { navigate, Redirect, Router } from '@reach/router';
import { fetchMediaByUuid } from '../lib/client-api';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useQueryParam } from 'use-query-params';
import { useRole } from '../context/role-context';
import { AssetIndex } from './asset/index';
import Crop from './crop';
import { AssetPicker } from './asset/picker';

export function Asset({ mediaId }) {
  const { role } = useRole();

  const [masterId, setMasterId] = useState();
  const [productId] = useQueryParam('productid');
  const [groupId] = useQueryParam('groupid');

  const pimReference = productId || groupId;

  const { data, isLoading } = useQuery(['fetchMediaByUuid', mediaId], () =>
    fetchMediaByUuid({ mediaId })
  );

  function handleUploadStart() {
    setMasterId(mediaId);
  }

  function handleAssetClick() {
    setMasterId(mediaId);
  }

  async function handleUploadSuccess(mediaId) {
    const newAsset = await fetchMediaByUuid({ mediaId });

    toast.dismiss();

    if (productId) {
      navigate(`/asset/${newAsset.id}/crop?productid=${pimReference}`);
    } else if (groupId) {
      navigate(`/asset/${newAsset.id}/crop?groupid=${pimReference}`);
    } else {
      navigate(`/asset/${newAsset.id}/crop`);
    }
  }

  if (!role) {
    return <Redirect to="../../" noThrow />;
  }

  return (
    <Router>
      <AssetIndex
        data={data}
        isLoading={isLoading}
        masterId={masterId}
        mediaId={mediaId}
        onAssetClick={handleAssetClick}
        onUploadSuccess={handleUploadSuccess}
        onUploadStart={handleUploadStart}
        path="/"
      />
      <AssetPicker onAssetClick={handleAssetClick} path="picker" />
      <Crop data={data} masterId={masterId} path="crop" />
    </Router>
  );
}
