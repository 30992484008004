import React, { useState } from 'react';

const LocaleContext = React.createContext();

function LocaleProvider(props) {
  const [locales, setLocales] = useState([]);

  return <LocaleContext.Provider value={{ locales, setLocales }} {...props} />;
}

function useLocale() {
  const context = React.useContext(LocaleContext);

  if (context === undefined) {
    throw new Error('useLocale must be used within a LocaleProvider');
  }

  return context;
}

export { LocaleProvider, useLocale };
